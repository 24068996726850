import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect } from 'react';
import {CampaignIframeOverlayConstants} from '../../adapters/helpers/Constants';
import Modal from 'react-modal';
import DOMPurify from 'isomorphic-dompurify';
import Block from '../../adapters/helpers/Block';

function CampaignIframeOverlay(props) {
    const moduleId = props.document?.fields?.moduleId;
    const iframeContent = props.document?.fields?.iFrameContent;
    const className = props.document?.fields?.classNames;
    const overlayEnabled = props.document?.fields?.overlayEnabled;
    const overlayForAllPages = props.document?.fields?.overlayForAllPages;
    const localStorageVariable =  CampaignIframeOverlayConstants.iframeOverlayTriggered;
    const isTemporaryPopup = props?.document?.fields?.isTemporaryPopup;
    const contentBlock = props?.document?.fields?.contentBlocks;
            
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            background          : 'none',
            border              : 'none',
            borderRadius        : '0',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            padding             : '0',
            maxWidth            : '1100px',
            transform           : 'translate(-50%, -50%)',
            width               : '100%'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);


    useLayoutEffect(() => {
        setTimeout(() => {
            if(iframeContent && overlayEnabled && !Boolean(localStorage.getItem(localStorageVariable)) && (overlayForAllPages || sessionStorage.getItem('isEnableCampaignOverlay'))) {
                setModalIsOpen(true);
                sessionStorage.removeItem('isEnableCampaignOverlay');
                localStorage.setItem(localStorageVariable, true);
            } else {
                setModalIsOpen(false);
            }
        }, 10000);
       
    }, []);

    const closeModal = () => {
        setModalIsOpen(false);        
    };
    
    return (
        modalIsOpen && <div className="ob-campaignoverlay">            
            <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={250}
                    style={customModalStyles}>
                    <div className='ob-campaignoverlay-modal'>
                    <div id="close_campaignoverlay" role='button' tabIndex={0} className="ob-campaignoverlay-close event_button_click" onClick={() => closeModal()}><span>{"x"}</span>
                    </div>
                    {isTemporaryPopup === true ? new Block(contentBlock).renderSpecifiedBlock(contentBlock) : 
                        <div
                            className={className}
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(iframeContent,{ ADD_TAGS: ["iframe"] },{ ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'ariaLabel', 'style', 'onload'] }) }}
                        >
                        </div>
                    }
                    </div>
            </Modal>           
        </div>        
    );
}

export default CampaignIframeOverlay;

CampaignIframeOverlay.propTypes = {
    document: PropTypes.object
};